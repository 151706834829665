import {
  parseCards,
  ParsedVideoCard,
  isGraphAPIEnabled,
} from '@wix/wix-vod-shared/common';
import { toError } from '../../parsers/transport-error';
import { publicApiV3 } from '../../v3/public';
import { getPublicApi } from '../index';

export type GetVideoCardsResponse = {
  data: ParsedVideoCard[];
};

export const getVideoCards =
  (channelId: string, videoId: string) =>
  (): Promise<GetVideoCardsResponse> => {
    const { oldPublicVideoCardsService } = getPublicApi();
    const promise = isGraphAPIEnabled()
      ? publicApiV3.cards.getList(videoId)
      : oldPublicVideoCardsService.getList(channelId, videoId);

    return promise
      .then((response) => ({
        ...response,
        data: parseCards(response.data),
      }))
      .catch(toError);
  };
