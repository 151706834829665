import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Nav from '@wix/wix-vod-shared/dist/esm/widget/ui-components/nav/nav';
import Animate from '@wix/wix-vod-shared/dist/esm/widget/ui-components/animate/animate';
import focus from '@wix/wix-vod-shared/dist/esm/widget/utils/accessibility-focus';
import { withTranslation } from '@wix/yoshi-flow-editor';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/esm/widget/ui-selectors/selected-video-id';
import { canPlayFullOrPreview } from '@wix/wix-vod-shared/dist/esm/widget/ui-selectors/video-access';
import {
  getChannelForWidget,
  getChannelCoverUrl,
} from '@wix/wix-vod-shared/common';
import {
  COMPACT_VIDEOS_IN_ROW,
  COMPACT_NUMBER_OF_ROWS,
} from '@wix/wix-vod-constants/app-settings';
import {
  isThumbnailsPreviewHover,
  canShowActionBar,
  canShowChannelShare,
  canShowVideoList,
  getCanShowSignIn,
  getCanShowChannelInfo,
  isPlayInFrame,
  isSingleVideo,
  isMainItemChannelCover,
  isRTL,
} from '../../selectors/app-settings';
import { isMobile } from '../../selectors/form-factor';
import { isSubscriptionButtonVisible } from '../../components/overlay-texts/channel/subscribe-button-text-utils';
import { sendBiEvent } from '../../bi/send-bi-event';
import {
  openFullScreenVideoOverlay,
  closeFullScreenVideoOverlay,
} from '../../redux/actions/full-screen-modal';
import { playerSize } from '../../utils/videos-sizes/videos-sizes';
import { getMainVideo } from '../../selectors/get-video';
import { showAutoPlay, isAllCardsOverlayVisible } from '../../selectors/layout';
import {
  isVideoPlayingOptimistic,
  isVideoPausedOptimistic,
} from '../../selectors/video-playback-status';
import { getVideosGroupedByIds } from '../../selectors/videos';
import { getCurrentSiteUser } from '../../selectors/current-site-user';
import { goToLazyVideosPageIndex } from '../../redux/lazy-channel-videos/actions';
import {
  selectVideo,
  resetSelectedVideo,
} from '../../redux/actions/select-video';
import { setSavedTime } from '../../redux/actions/player/set-saved-time';
import {
  playVideo,
  pauseVideo,
  resetPlaybackStatus,
} from '../../redux/actions/player/change-playback-status';
import { requestPlayVideo } from '../../redux/actions/request-play-video';
import { closeShareOverlay } from '../../redux/actions/share-overlay';
import { logWidgetVidClick } from '../../utils/bi/widget-common-events';
import { logWidgetSystem } from '../../worker/actions/bi';
import PaymentEvents from '../../components/payment-events/payment-events';
import LiveStartHandler from '../../components/live-start-handler/live-start-handler';
import ResizeWidgetByContent from '../../components/resize-widget-by-content/resize-widget-by-content';
import DeeplinkPopups from '../../components/deeplink-popups/deeplink-popups';
import ShareOverlay from '../../containers/share-overlay/share-overlay';
import PlayerBlocksVisibility from '../../containers/player-blocks-visibility';
import Videos from './components/videos/videos';
import ChannelOverlay from './components/player-overlay/channel-overlay';
import VideoOverlay from './components/player-overlay/video-overlay';
import ChannelActions from './components/channel-actions/channel-actions';
import ActionBar from './components/action-bar/action-bar';
import MainItemSlider from '../../components/main-item-slider/main-item-slider';
import AccessibleVideosContainer from '../../components/accessible-videos-container/accessible-videos-container';
import InFrameActionCallbacks from './actions/overlay-action-callbacks/in-frame';
import InFrameMobileActionCallbacks from './actions/overlay-action-callbacks/in-frame-mobile';
import InOverlayActionCallbacks from './actions/overlay-action-callbacks/in-overlay';
import ChannelActionsContainer from '../../containers/channel-actions/channel-actions';
import PlayerActionCallbacks from './actions/player-action-callbacks';
import AutoPlayVideo from '../../components/autoplay-video/autoplay-video';
import { WidgetPerformanceLoggers } from '../../containers/performance-loggers/performance-loggers';
import { withPlayerModuleLoader } from '../../data-components/player-module-loader';
import styles from './compact.scss';
import {
  getVideoIdsByPageNumber,
  getCurrentPageIndex,
  getCurrentVideoIndex,
  getIsFetching,
  getVideoIds,
  hasNextVideo,
  hasPrevVideo,
} from '../../redux/lazy-channel-videos/selectors';
import { ComingSoon } from '../../components/coming-soon/coming-soon';
import { withFedopsInteractions } from '../../fedops/interactions';

const DEFAULT_STATE = {
  areAllVideosVisible: false,
  isShareOverlayVisible: false,
};

export const MIN_WIDGET_WIDTH = 400;
export const MAX_WIDGET_WIDTH = 980;

const ACTION_BAR_OPENED_HEIGHT = 60;
const ACTION_BAR_CLOSED_HEIGHT = 40;

const mapStateToProps = (state) => ({
  windowSize: state.windowSize,
  videoIdsByPageNumber: getVideoIdsByPageNumber(state),
  currentVideosPageNumber: getCurrentPageIndex(state),
  lazyLoadedVideoIds: getVideoIds(state),
  isVideoPaused: isVideoPausedOptimistic(state),
  isVideoPlaying: isVideoPlayingOptimistic(state),
  isPlayInFrame: isPlayInFrame(state),
  mainVideoId: getMainVideoId(state),
  mainVideo: getMainVideo(state),
  selectedVideoId: state.selectedVideoId,
  hasNextVideo: hasNextVideo(state),
  hasPrevVideo: hasPrevVideo(state),
  currentIdIndex: getCurrentVideoIndex(state),
  videoByIds: getVideosGroupedByIds(state),
  isVideosFetching: getIsFetching(state),
  currentSiteUser: getCurrentSiteUser(state),
  canShowAutoPlay: showAutoPlay(state),
  canShowChannelCover: !state.isVideosTouched,
  canShowChannelInfo: getCanShowChannelInfo(state),
  canShowChannelShare: canShowChannelShare(state),
  canShowVideoList: canShowVideoList(state),
  canShowSignIn: getCanShowSignIn(state),
  canShowSubscriptionButton: isSubscriptionButtonVisible({
    channel: getChannelForWidget(state),
  }),
  isThumbnailsPreviewHover: isThumbnailsPreviewHover(state),
  isActionBarVisible: canShowActionBar(state),
  isAllCardsOverlayVisible: isAllCardsOverlayVisible(state),
  isRTL: isRTL(state),
  channelData: getChannelForWidget(state),
  isSingleVideo: isSingleVideo(state),
  isMainItemChannelCover: isMainItemChannelCover(state),
  isMobile: isMobile(state),
});

const mapDispatchToProps = {
  selectVideo,
  playVideo,
  pauseVideo,
  requestPlayVideo,
  resetSelectedVideo,
  resetPlaybackStatus,
  closeShareOverlay,
  setSavedTime,
  goToLazyVideosPageIndex,
  openFullScreenVideoOverlay,
  closeFullScreenVideoOverlay,
  logWidgetSystem,
  logWidgetVidClick,
  sendBiEvent,
};

const CompactViewComponent = withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class CompactView extends React.Component {
      static displayName = 'CompactView';

      static propTypes = {
        currentSiteUser: PropTypes.object,
        mainVideoId: PropTypes.string,
        mainVideo: PropTypes.object,
        isVideosFetching: PropTypes.bool,
        playVideo: PropTypes.func.isRequired,
        selectVideo: PropTypes.func.isRequired,
        canShowChannelCover: PropTypes.bool.isRequired,
        channelData: PropTypes.object.isRequired,
        videoByIds: PropTypes.object.isRequired,
        videoIdsByPageNumber: PropTypes.array,
        windowSize: PropTypes.object.isRequired,
        currentIdIndex: PropTypes.number,
        hasNextVideo: PropTypes.bool.isRequired,
        hasPrevVideo: PropTypes.bool.isRequired,
        isActionBarVisible: PropTypes.bool,
        canShowAutoPlay: PropTypes.bool,
        canShowChannelShare: PropTypes.bool,
        canShowChannelInfo: PropTypes.bool,
        canShowVideoList: PropTypes.bool,
        canShowSignIn: PropTypes.bool,
        isThumbnailsPreviewHover: PropTypes.bool,
        openFullScreenVideoOverlay: PropTypes.func,
        closeFullScreenVideoOverlay: PropTypes.func,
        resetPlaybackStatus: PropTypes.func,
        resetSelectedVideo: PropTypes.func,
        isSingleVideo: PropTypes.bool,
        setSavedTime: PropTypes.func,
        goToLazyVideosPageIndex: PropTypes.func.isRequired,
        isMainItemChannelCover: PropTypes.bool.isRequired,
        requestPlayVideo: PropTypes.func.isRequired,
        selectedVideoId: PropTypes.string,
        isVideoPlaying: PropTypes.bool.isRequired,
        lazyLoadedVideoIds: PropTypes.array.isRequired,
        isPlayInFrame: PropTypes.bool.isRequired,
        canShowSubscriptionButton: PropTypes.bool.isRequired,
        currentVideosPageNumber: PropTypes.number.isRequired,
        pauseVideo: PropTypes.func.isRequired,
        closeShareOverlay: PropTypes.func.isRequired,
        isVideoPaused: PropTypes.bool.isRequired,
        isAllCardsOverlayVisible: PropTypes.bool.isRequired,
        PlayerComponent: PropTypes.func,
        isPortableDevice: PropTypes.bool,
        isRTL: PropTypes.bool,
      };

      constructor(props) {
        super(props);

        this.state = DEFAULT_STATE;
      }

      UNSAFE_componentWillReceiveProps({ isVideoPlaying }) {
        if (isVideoPlaying && !this.props.isVideoPlaying) {
          this.setState({ areAllVideosVisible: false });
        }
      }

      componentDidUpdate(prevProps, prevState) {
        const { selectedVideoId, canShowVideoList, canShowChannelShare } =
          this.props;
        const { areAllVideosVisible, isShareOverlayVisible } = this.state;

        const isAllVideosVisibilityChanged =
          prevState.areAllVideosVisible !== areAllVideosVisible;

        const isSelectedVideoChanged =
          prevProps.selectedVideoId !== selectedVideoId;
        if (isSelectedVideoChanged) {
          // Because of animation for 0.3s. Needed to read updated player overlay
          // onTransitionEnd can`t be used because selected video can be updated to not rendered yet
          setTimeout(() => {
            // To read just current overlay instead of all rendered
            const visibleOverlayRef =
              this.playerOverlayContainerRef &&
              this.playerOverlayContainerRef.querySelector(
                '[data-is-visible-overlay="true"]',
              );
            focus(visibleOverlayRef);
          }, 400);
        }

        const isAllVideosWasOpened =
          canShowVideoList &&
          isAllVideosVisibilityChanged &&
          areAllVideosVisible;
        if (isAllVideosWasOpened) {
          focus(this.videosContainerRef);
        }

        const isShareOverlayWasOpened =
          canShowChannelShare &&
          isShareOverlayVisible &&
          prevState.isShareOverlayVisible !== isShareOverlayVisible;

        if (isShareOverlayWasOpened) {
          focus(this.shareOverlayContainerRef);
        }
      }

      videosContainerRef = null;

      // FIXME: playerOverlayContainerRef and shareOverlayContainerRef are not saved!
      playerOverlayContainerRef = null;
      shareOverlayContainerRef = null;

      saveVideosContainerRef = (ref) => {
        this.videosContainerRef = ref;
      };

      sendWidgetVidClickedBi = (videoItem) => {
        const { channelData, logWidgetVidClick, interactions, isPlayInFrame } =
          this.props;

        logWidgetVidClick({ videoItem, channelData });

        if (isPlayInFrame) {
          interactions.start('COMPACT_PLAY');
        }
      };

      reset = () => {
        this.setState(DEFAULT_STATE);
        this.props.goToLazyVideosPageIndex(0);
      };

      showAllVideos = () => {
        const {
          isVideoPlaying,
          pauseVideo,
          currentVideosPageNumber,
          goToLazyVideosPageIndex,
        } = this.props;

        this.props.sendBiEvent('widget.actionBar.open.click');

        if (isVideoPlaying) {
          pauseVideo();
        }

        this.setState({
          areAllVideosVisible: true,
        });

        if (!currentVideosPageNumber) {
          goToLazyVideosPageIndex(0);
        }

        this.props.logWidgetSystem('videoList.show.requested');
      };

      hideAllVideos = () => {
        this.setState({
          areAllVideosVisible: false,
        });

        this.props.closeShareOverlay();
      };

      toggleShareOverlay = () => {
        this.setState({
          isShareOverlayVisible: !this.state.isShareOverlayVisible,
        });
      };

      hideShareOverlay = () => {
        this.setState({
          isShareOverlayVisible: false,
        });
      };

      getShareOverlay() {
        const { channelData, mainVideo } = this.props;
        const key = mainVideo ? mainVideo.id : channelData.id;
        return (
          <ShareOverlay
            key={`share-${key}`}
            channelData={channelData}
            videoItem={mainVideo}
          />
        );
      }

      renderMainItemSliderNav() {
        const { hasPrevVideo, hasNextVideo, isRTL } = this.props;

        return (
          <Nav
            prevButtonClassName={styles['nav-button-prev']}
            nextButtonClassName={styles['nav-button-next']}
            isPrevVisible={hasPrevVideo}
            isNextVisible={hasNextVideo}
            isRTL={isRTL}
            prevButtonAriaLabel={this.props.t(
              'widget.accessibility.prev-video',
            )}
            nextButtonAriaLabel={this.props.t(
              'widget.accessibility.next-video',
            )}
            onNextClick={this.selectNextVideo}
            onPrevClick={this.selectPrevVideo}
          />
        );
      }

      isVideoItemAtChannelCover(videoId) {
        return (
          this.props.isMainItemChannelCover &&
          videoId === this.props.mainVideoId
        );
      }

      isAtChannelCover(videoId) {
        return (
          this.props.canShowChannelCover &&
          this.isVideoItemAtChannelCover(videoId)
        );
      }

      renderPlayerOverlayItem = (videoId, isVisible) => {
        const { width, height } = this.playerSize;
        const { channelData, currentSiteUser, videoByIds, mainVideoId } =
          this.props;
        const Overlay = this.isAtChannelCover(videoId)
          ? ChannelOverlay
          : VideoOverlay;
        const videoItem = videoByIds[videoId];
        const OverlayActionsCallbacks = this.overlayActionsCallbacks;

        return (
          <OverlayActionsCallbacks
            isMainVideo={videoId === mainVideoId}
            onBeforeClick={this.hideAllVideos}
            onBeforePlayRequested={this.hideAllVideos}
            onPlayRequestedBi={_.partial(
              this.sendWidgetVidClickedBi,
              videoItem,
            )}
            channelId={channelData.id}
            videoId={videoItem.id}
            videoItem={videoItem}
          >
            <Overlay
              currentSiteUser={currentSiteUser}
              width={width}
              height={height}
              isContentFocusable={isVisible}
              channelData={channelData}
              videoItem={videoItem}
            />
          </OverlayActionsCallbacks>
        );
      };

      renderMainItemSlider = () => {
        const {
          lazyLoadedVideoIds,
          mainVideo,
          mainVideoId,
          isSingleVideo,
          isVideosFetching,
          currentIdIndex,
          channelData,
        } = this.props;

        const loadedVideoIds = isSingleVideo
          ? [mainVideoId]
          : lazyLoadedVideoIds;

        if (!mainVideo && !isVideosFetching) {
          return <ComingSoon coverUrl={getChannelCoverUrl(channelData)} />;
        }

        if (!mainVideoId) {
          return null;
        }

        if (currentIdIndex === -1) {
          return this.renderPlayerOverlayItem(mainVideoId, true);
        }

        return (
          <MainItemSlider
            className={styles['main-video-slider']}
            loadedVideoIds={loadedVideoIds}
            mainVideoId={mainVideoId}
            isMainVideoAtChannelCover={this.isAtChannelCover(mainVideoId)}
            renderItem={this.renderPlayerOverlayItem}
          />
        );
      };

      handlePlayStart = () => {
        this.props.interactions.end('COMPACT_PLAY');
      };

      get player() {
        const { mainVideo, canShowAutoPlay } = this.props;

        if (!mainVideo) {
          return null;
        }

        const { width, height } = this.playerSize;
        const {
          channelData,
          isPlayInFrame,
          isVideoPlaying,
          currentSiteUser,
          PlayerComponent,
          isPortableDevice,
        } = this.props;

        const isPlayAllowed = canPlayFullOrPreview(
          channelData,
          mainVideo,
          currentSiteUser,
        );

        if (!isPlayAllowed || !PlayerComponent) {
          return null;
        }

        const playerProps = {
          videoItem: mainVideo,
          channelData,
          width,
          height,
          paused: !isVideoPlaying,
          showPoster: false,
        };

        const callbackProps = {
          channelId: channelData.id,
          videoId: mainVideo.id,
        };

        if (isPortableDevice) {
          return (
            <PlayerBlocksVisibility>
              {({ canShowVideoInfoButton, canShowShareButton }) => (
                <PlayerActionCallbacks {...callbackProps}>
                  <PlayerComponent
                    {...playerProps}
                    canShowFullInfo={canShowVideoInfoButton}
                    canShareVideo={canShowShareButton}
                  >
                    {canShowAutoPlay && <AutoPlayVideo />}
                  </PlayerComponent>
                </PlayerActionCallbacks>
              )}
            </PlayerBlocksVisibility>
          );
        }

        if (isPlayInFrame) {
          return (
            <React.Fragment>
              <PlayerBlocksVisibility>
                {({ canShowVideoInfoButton, canShowShareButton }) => (
                  <PlayerActionCallbacks {...callbackProps}>
                    <PlayerComponent
                      {...playerProps}
                      canShowFullInfo={canShowVideoInfoButton}
                      canShareVideo={canShowShareButton}
                      onPlayStart={this.handlePlayStart}
                    />
                  </PlayerActionCallbacks>
                )}
              </PlayerBlocksVisibility>
              <AutoPlayVideo />
            </React.Fragment>
          );
        }

        return null;
      }

      get playerContentSize() {
        const { canShowVideoList } = this.props;

        if (!canShowVideoList) {
          return this.playerSize;
        }

        const { width, height } = this.playerSize;
        return {
          width,
          height: height - (this.actionBarHeight - ACTION_BAR_CLOSED_HEIGHT),
        };
      }

      get playerSize() {
        const { windowSize } = this.props;
        const width = Math.min(
          Math.max(windowSize.width, MIN_WIDGET_WIDTH),
          MAX_WIDGET_WIDTH,
        );
        return playerSize(width, 0);
      }

      get actionBarHeight() {
        return this.areActionsVisible
          ? ACTION_BAR_OPENED_HEIGHT
          : ACTION_BAR_CLOSED_HEIGHT;
      }

      get thumbnailSize() {
        const { width, height } = this.playerSize;

        return {
          width: width / COMPACT_VIDEOS_IN_ROW,
          height:
            (height - (ACTION_BAR_OPENED_HEIGHT - ACTION_BAR_CLOSED_HEIGHT)) /
            COMPACT_NUMBER_OF_ROWS,
        };
      }

      handleSlideToPrev = () => {
        this.props.logWidgetSystem('videoList.changePage.requested');
        const { currentVideosPageNumber, goToLazyVideosPageIndex } = this.props;
        goToLazyVideosPageIndex(currentVideosPageNumber - 1);
      };

      handleSlideToNext = () => {
        this.props.logWidgetSystem('videoList.changePage.requested');
        const { currentVideosPageNumber, goToLazyVideosPageIndex } = this.props;
        goToLazyVideosPageIndex(currentVideosPageNumber + 1);
      };

      renderVideos() {
        const {
          channelData,
          videoIdsByPageNumber,
          currentVideosPageNumber,
          canShowVideoList,
          isVideosFetching,
          isRTL,
          mainVideo,
        } = this.props;

        const OverlayActionsCallbacks = this.overlayActionsCallbacks;

        if (!canShowVideoList || !mainVideo) {
          return null;
        }

        return (
          <AccessibleVideosContainer
            className={styles['videos-container']}
            channelTitle={channelData.title}
            isChannelHasVideos={Boolean(channelData.videosCount)}
            isVideosFetching={isVideosFetching}
            videoIdsByPageNumber={videoIdsByPageNumber}
            currentVideosPageNumber={currentVideosPageNumber}
            style={{ height: this.videosListHeight }}
            onRef={this.saveVideosContainerRef}
          >
            <Videos
              containerWidth={this.playerContentSize.width}
              channelData={channelData}
              isVisible={this.isVideoListVisible}
              isRTL={isRTL}
              videoIdsByPageNumber={videoIdsByPageNumber}
              currentVideosPageNumber={currentVideosPageNumber}
              onSlideToPrev={this.handleSlideToPrev}
              onSlideToNext={this.handleSlideToNext}
              onBeforeClick={this.hideAllVideos}
              onBeforePlayRequested={this.hideAllVideos}
              onPlayRequestedBi={this.sendWidgetVidClickedBi}
              OverlayActionsCallbacks={OverlayActionsCallbacks}
              thumbnailSize={this.thumbnailSize}
            />
          </AccessibleVideosContainer>
        );
      }

      selectNextVideo = () => {
        const { selectVideo, lazyLoadedVideoIds, currentIdIndex } = this.props;
        selectVideo(lazyLoadedVideoIds[currentIdIndex + 1]);
      };

      selectPrevVideo = () => {
        const { selectVideo, lazyLoadedVideoIds, currentIdIndex } = this.props;
        selectVideo(lazyLoadedVideoIds[currentIdIndex - 1]);
      };

      setCurrentVideoFromPayment = (paymentEvent) => {
        if (paymentEvent.itemId) {
          this.props.selectVideo(paymentEvent.itemId);
        }
      };

      handleActionBarContainerClick = () => {
        this.hideAllVideos();
        this.hideShareOverlay();
      };

      get actionBar() {
        const { isActionBarVisible } = this.props;

        if (!isActionBarVisible) {
          return null;
        }

        return (
          <ActionBar
            height={this.actionBarHeight}
            areActionsVisible={this.areActionsVisible}
            onActionsContainerClick={this.handleActionBarContainerClick}
            onMenuClick={this.showAllVideos}
            onButtonsClick={this.hideShareOverlay}
          >
            {this.actions}
          </ActionBar>
        );
      }

      handleShareRequest = (clickEvent) => {
        clickEvent.stopPropagation();
        this.toggleShareOverlay();
      };

      get actions() {
        const {
          channelData,
          canShowVideoList,
          canShowSignIn,
          canShowChannelShare,
          canShowChannelInfo,
          canShowSubscriptionButton,
          mainVideo,
        } = this.props;

        return (
          // TODO onUserLogin, onUserLogOut - etc. make BaseAccountButton dumb to only
          // call callbacks provided to it aka onUserLoginRequested...
          <ChannelActionsContainer channelId={channelData.id}>
            {(channelActions) => (
              <ChannelActions
                channelData={channelData}
                videoItem={mainVideo}
                canShowSignIn={canShowSignIn}
                canShowChannelShare={canShowChannelShare}
                canShowChannelInfo={canShowChannelInfo}
                canShowCloseButton={canShowVideoList}
                canShowSubscriptionButton={canShowSubscriptionButton}
                onShareRequest={this.handleShareRequest}
                onCloseRequest={this.hideAllVideos}
                onLogInRequest={channelActions.logIn}
                onLogOutRequest={channelActions.logOut}
                onInfoRequest={channelActions.showInfo}
                onSubscriptionRequest={channelActions.subscribe}
                onSubscriptionCancelRequest={channelActions.cancelSubscription}
                onAccountInfoAtOverlayRequest={channelActions.showAccountInfo}
              />
            )}
          </ChannelActionsContainer>
        );
      }

      get isVideoListVisible() {
        const { areAllVideosVisible } = this.state;
        const { isThumbnailsPreviewHover, canShowVideoList } = this.props;
        if (!canShowVideoList) {
          return false;
        }
        return areAllVideosVisible || isThumbnailsPreviewHover;
      }

      get areActionsVisible() {
        const { canShowVideoList } = this.props;
        return !canShowVideoList || this.isVideoListVisible;
      }

      get overlayActionsCallbacks() {
        const { isPlayInFrame, isPortableDevice } = this.props;

        if (isPortableDevice) {
          return InFrameMobileActionCallbacks;
        }

        if (isPlayInFrame) {
          return InFrameActionCallbacks;
        }

        return InOverlayActionCallbacks;
      }

      isPlayAllowed(id) {
        const { channelData, videoByIds, currentSiteUser } = this.props;
        return canPlayFullOrPreview(
          channelData,
          videoByIds[id],
          currentSiteUser,
        );
      }

      playVideo = ({ id }) => {
        const {
          isPlayInFrame,
          channelData,
          openFullScreenVideoOverlay,
          closeFullScreenVideoOverlay,
          requestPlayVideo,
          isPortableDevice,
        } = this.props;

        if (isPlayInFrame || isPortableDevice) {
          requestPlayVideo(id);
        } else {
          openFullScreenVideoOverlay(
            channelData.id,
            id,
            this.isPlayAllowed(id),
            closeFullScreenVideoOverlay,
          );
        }
      };

      render() {
        const {
          isVideoPlaying,
          isVideoPaused,
          channelData,
          selectedVideoId,
          isAllCardsOverlayVisible,
          isPortableDevice,
        } = this.props;

        const isMainItemVisible =
          !isVideoPlaying &&
          !isAllCardsOverlayVisible &&
          (isPortableDevice || !isVideoPaused);

        return (
          <ResizeWidgetByContent>
            <main
              className={styles.container}
              data-hook="widget-container"
              data-channel-layout="compact"
              aria-label={this.props.t(
                'widget.accessibility.channel-videos-widget',
                {
                  channelTitle: channelData.title,
                },
              )}
              tabIndex="0"
            >
              <div className={styles.content} style={this.playerContentSize}>
                <Animate
                  type="fade-in"
                  dataHook="video-list"
                  className={styles['videos-in-animation']}
                  when={this.isVideoListVisible}
                >
                  {this.renderVideos()}
                </Animate>
                <Animate
                  type="fade-in"
                  dataHook="player-content"
                  when={!this.isVideoListVisible}
                >
                  {this.player}
                  {isMainItemVisible && this.renderMainItemSlider()}
                  {isMainItemVisible &&
                    !this.isVideoListVisible &&
                    this.renderMainItemSliderNav()}
                  <DeeplinkPopups />
                </Animate>
                {this.getShareOverlay()}
              </div>

              {this.actionBar}

              <PaymentEvents
                onRent={this.setCurrentVideoFromPayment}
                onSale={this.setCurrentVideoFromPayment}
                onSubscription={this.reset}
                onSubscriptionCancel={this.reset}
              />
              <LiveStartHandler
                playVideo={this.playVideo}
                isVideoPlaying={isVideoPlaying}
                selectedVideoId={selectedVideoId}
              />
              <WidgetPerformanceLoggers />
            </main>
          </ResizeWidgetByContent>
        );
      }
    },
  ),
);

export const CompactView = withFedopsInteractions(CompactViewComponent);
export default withPlayerModuleLoader(CompactView);
